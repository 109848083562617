export enum USER_FLAG {
  CAN_ACCESS_OLD_LEGAL_CREATION_ONBOARDING_MODULE = 'canAccessOldLegalCreationOnboardingModule',
  CAN_DELETE_STRIPE_CUSTOMER = 'canDeleteStripeCustomer',
  CAN_MANAGE_PERIOD_STARTS_AT_SUBSCRIPTION = 'canManagePeriodStartsAtSubscription',
  MANUAL_DOCUMENTS_VERIFICATION = 'manualDocumentsVerification',
  CAN_DELETE_TASK = 'canDeleteTask',
  CAN_SEE_INVOICING_BALANCE = 'canSeeInvoicingBalance',
  CAN_SEE_TASK_LEGAL_STATE = 'canSeeTaskLegalState',
  CAN_SEE_POST_ITS = 'canSeePostIts',
  CAN_HAVE_ACCOUNTING_THIRTY_MINUTES_APPOINTMENT = 'canHaveAccountingThirtyMinutesAppointment',
  CAN_ACCESS_AND_UPDATE_SERVICE_TEMPLATES = 'canAccessAndUpdateServiceTemplates',
  HAS_ACCOUNTING_ONBOARDER = 'hasAccountingOnboarder',
  SHOW_DESIRED_PLAN_FOR_ONBOARDING_CREATION = 'showDesiredPlanForOnboardingCreation',
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { distinctUntilKeyChanged, EMPTY, from, lastValueFrom, map, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { User, UserDestroyParams, UserInfo } from '@dougs/user/dto';
import { AvatarHttpService } from '../http/avatar.http';
import { UserHttpService } from '../http/user.http';

interface UserState {
  usersInfo: UserInfo[];
  activeUser: User;
  loggedInUser: User;
}

@Injectable({
  providedIn: 'root',
})
export class UserStateService extends StateService<UserState> {
  activeUser$: Observable<User> = this.select<User>((state) => state.activeUser);
  activeUserIdChanged$: Observable<User> = this.activeUser$.pipe(
    filter((activeUser) => !!activeUser),
    distinctUntilKeyChanged('id'),
  );
  loggedInUser$: Observable<User> = this.select<User>((state) => state.loggedInUser);
  loggedInUserIdChanged$: Observable<User> = this.loggedInUser$.pipe(
    filter((loggedInUser) => !!loggedInUser),
    distinctUntilKeyChanged('id'),
  );
  usersInfo$: Observable<UserInfo[]> = this.select<UserInfo[]>((state) => state.usersInfo);

  activeUserFirstName$: Observable<string> = this.select((state) => state.activeUser.profile?.firstName || '');

  activeUserFullName$: Observable<string> = this.select((state) => state.activeUser.profile?.fullName || '');

  activeUserRoleHasChanged$: Subject<User> = new Subject<User>();

  USER_SEARCH_LIMIT = 10;
  USER_SEARCH_OFFSET = 0;

  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly logger: LoggerService,
    private readonly avatarHttpService: AvatarHttpService,
  ) {
    super();
  }

  get loggedInUser(): User {
    return this.state?.loggedInUser;
  }

  get activeUser(): User {
    return this.state?.activeUser;
  }

  get activeUserCompanyId(): number {
    return this.state?.activeUser?.companyIds?.length
      ? this.state?.activeUser.companyIds[0]
      : this.state?.activeUser.company.id;
  }

  get loggedInUserCompanyId(): number {
    return this.state?.loggedInUser?.companyIds?.length
      ? this.state?.loggedInUser.companyIds[0]
      : this.state?.loggedInUser.company.id;
  }

  async activeUserHasChanged(currentUserId: number): Promise<User | null> {
    try {
      const activeUser: User = await lastValueFrom(this.userHttpService.getUser(currentUserId));
      this.setState({
        activeUser,
      });

      return activeUser;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshActiveUser(activeUser: Pick<User, 'id'>): Promise<void> {
    try {
      const refreshedActiveUser: User = await lastValueFrom(this.userHttpService.getUser(activeUser.id));
      this.setActiveUserState(refreshedActiveUser);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateUserWithoutRefreshState(user: User): Promise<void> {
    try {
      await lastValueFrom(this.userHttpService.updateUser(user));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateActiveUser(user: User): Promise<boolean> {
    try {
      const updatedUser: User = await lastValueFrom(this.userHttpService.updateUser(user));
      this.setActiveUserState({
        ...this.state.activeUser,
        ...updatedUser,
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async removeFlag(flag: string): Promise<void> {
    try {
      if (this.state.activeUser.flags.includes(flag)) {
        this.setActiveUserState(await lastValueFrom(this.userHttpService.removeFlag(this.state.activeUser, flag)));
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addFlag(flag: string): Promise<void> {
    try {
      if (!this.state.activeUser.flags.includes(flag)) {
        this.setActiveUserState(await lastValueFrom(this.userHttpService.addFlag(this.state.activeUser, flag)));
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateRole(role: string): Promise<void> {
    try {
      this.setActiveUserState(await lastValueFrom(this.userHttpService.updateRole(this.state.activeUser, role)));

      this.activeUserRoleHasChanged$.next(this.state.activeUser);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateTeam(teamId: number): Promise<void> {
    try {
      this.setActiveUserState(await lastValueFrom(this.userHttpService.updateTeam(this.state.activeUser, teamId)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async finalizeSignup(): Promise<boolean> {
    try {
      this.setActiveUserState(await lastValueFrom(this.userHttpService.finalizeSignup(this.state.activeUser)));

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  async finalizeOverride(): Promise<boolean> {
    try {
      await lastValueFrom(this.userHttpService.finalizeOverride(this.state.activeUser));
    } catch (e) {
      this.logger.error(e);
      return false;
    }
    return true;
  }

  async resetPassword(email = this.state.activeUser?.email): Promise<boolean> {
    try {
      await lastValueFrom(this.userHttpService.resetPassword(email));

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  async refreshUsersInfo(): Promise<void> {
    try {
      this.setState({
        usersInfo: this.getAndSetCacheState('userInfo')
          ? this.state.usersInfo
          : await lastValueFrom(this.userHttpService.getUsersInfo()),
      });
    } catch (e) {
      this.clearCache('userInfo');
      this.logger.error(e);
    }
  }

  async refreshLoggedInUser(): Promise<boolean> {
    try {
      this.setState({
        loggedInUser: this.getAndSetCacheState('me')
          ? this.state.loggedInUser
          : await lastValueFrom(this.userHttpService.me()),
      });

      return true;
    } catch (e: any) {
      if (e?.status === 403) {
        window.location.href = '/app/signin/suspended-user';
      }
      this.clearCache('me');
      this.logger.error(JSON.stringify(e));
      return false;
    }
  }

  async updateUserEmail(email: string, emailConfirmation: string, password: string): Promise<User | null> {
    try {
      const user: User = await lastValueFrom(
        this.userHttpService.updateUserEmail(this.state.activeUser, email, emailConfirmation, password),
      );
      this.setState({
        activeUser: {
          ...this.state.activeUser,
          ...user,
        },
      });

      return this.state.activeUser;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async sendEmailConfirmation(): Promise<boolean> {
    try {
      this.setState({
        activeUser: {
          ...this.state.activeUser,
          ...(await lastValueFrom(this.userHttpService.sendEmailConfirmation(this.state.activeUser))),
        },
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async hasSeenProductTour(): Promise<void> {
    try {
      this.setState({
        activeUser: {
          ...this.state.activeUser,
          ...(await lastValueFrom(
            this.userHttpService.updateUser({
              ...this.state.activeUser,
              metadata: {
                ...this.state.activeUser.metadata,
                hasSeenProductTour: true,
              },
            }),
          )),
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async hasSeenAccountingOnboardingMovie(): Promise<void> {
    try {
      this.setState({
        activeUser: {
          ...this.state.activeUser,
          ...(await lastValueFrom(
            this.userHttpService.updateUser({
              ...this.state.activeUser,
              metadata: {
                ...this.state.activeUser.metadata,
                hasSeenAccountingOnboardingMovie: true,
              },
            }),
          )),
        },
      });
    } catch (e) {
      this.logger.error(JSON.stringify(e));
    }
  }

  getUsers(hasFlags: string[] | null, q: string): Observable<User[]> {
    return this.userHttpService.getUsers(hasFlags, q);
  }

  getAssignableUsers(taskIds: number[], q: string): Observable<User[]> {
    return this.userHttpService.getAssignableUsers(taskIds, q);
  }

  async getReferrerUsers(q?: string): Promise<User[]> {
    try {
      return await lastValueFrom(this.userHttpService.getReferrerUsers(q));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async setUserProfile(naturalPersonId: number, password: string): Promise<boolean> {
    try {
      await lastValueFrom(this.userHttpService.setUserProfile(this.state.activeUser, naturalPersonId, password));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async destroyUser(userId: number, { force, notify, delay, reason }: UserDestroyParams): Promise<boolean> {
    try {
      await lastValueFrom(this.userHttpService.destroy(userId, { force, notify, delay, reason }));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async abortUserDestroy(user: User): Promise<boolean> {
    try {
      this.setActiveUserState(await lastValueFrom(this.userHttpService.abortDestroy(user)));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async getUserById(userId: number): Promise<User | null> {
    try {
      return await lastValueFrom(this.userHttpService.getUser(userId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  private isMe(): boolean {
    return this.state?.loggedInUser?.id === this.state?.activeUser?.id;
  }

  hasDarkModeEnabled(): boolean {
    if (this.state?.loggedInUser?.metadata?.darkMode === 'dark') {
      return true;
    }

    if (
      this.state?.loggedInUser?.metadata?.darkMode === 'system' &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return true;
    }

    return false;
  }

  login(email: string, password: string): Observable<{ redirect: string; loginComplete: boolean }> {
    return this.userHttpService.login(email, password).pipe(
      mergeMap(({ redirect, loginComplete }) => {
        if (loginComplete) {
          return from(this.refreshLoggedInUser()).pipe(
            filter((truth) => truth),
            map(() => ({ redirect, loginComplete })),
          );
        }
        return of({ redirect, loginComplete });
      }),
      catchError((err) => {
        const error = err.error?.message ?? err.message;
        this.logger.error(error);
        return throwError(error);
      }),
    );
  }

  changePassword(hash: string, passwords: { password: string; confirmPassword: string }): Observable<string> {
    return this.userHttpService.changePassword(hash, passwords).pipe(
      map(() => 'Le mot de passe a été mis à jour'),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          return EMPTY;
        }
        throw err;
      }),
    );
  }

  async initPassword(hash: string, passwords: { password: string; confirmPassword: string }): Promise<string | null> {
    try {
      await lastValueFrom(this.userHttpService.initPassword(hash, passwords));
      return 'Le mot de passe a été mis à jour';
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  setActiveUserState(user: User): void {
    try {
      if (this.isMe()) {
        this.setState({
          loggedInUser: user,
        });
      }

      this.setState({
        activeUser: user,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAvatar(user: User, file: File): Promise<boolean> {
    try {
      await lastValueFrom(this.avatarHttpService.uploadAvatar(user.id, file));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  ssoLogin(token: string): Observable<{ redirect: string }> {
    return this.userHttpService.ssoLogin(token);
  }

  searchUsers(searchPayload: string): Observable<User[]> {
    try {
      this.USER_SEARCH_OFFSET = 0;
      return this.userHttpService.searchUsers(
        searchPayload,
        this.state.loggedInUser.role,
        this.USER_SEARCH_LIMIT,
        this.USER_SEARCH_OFFSET * this.USER_SEARCH_LIMIT,
      );
    } catch (e) {
      this.logger.error(e);
      return of([]);
    }
  }

  loadMoreUsers(searchPayload: string): Observable<User[]> {
    try {
      this.USER_SEARCH_OFFSET++;
      return this.userHttpService.searchUsers(
        searchPayload,
        this.state.loggedInUser.role,
        this.USER_SEARCH_LIMIT,
        this.USER_SEARCH_OFFSET * this.USER_SEARCH_LIMIT,
      );
    } catch (e) {
      this.logger.error(e);
      return of([]);
    }
  }
}
